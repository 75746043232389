<template>
  <div>
    <maca-modal titulo="Modificar Producto" :impedirClose="impedirClose" ref="modal">
      <el-form :model="form" ref="form" :rules="formRules" label-width="150px" v-loading="cargando">
        <el-form-item label="Tipo" prop="tipo">
          <maca-select-box icon="el-icon-box" v-model="form.tipo" :url="urlTipo"></maca-select-box>
        </el-form-item>
        <el-form-item label="Marca" prop="marca">
          <maca-select-box icon="el-icon-collection-tag" v-model="form.marca" :url="urlMarca"></maca-select-box>
        </el-form-item>
        <el-form-item label="Descripción" prop="descripcion">
          <el-input v-model="form.descripcion"></el-input>
        </el-form-item>
        <el-form-item label="Por Defecto" prop="porDefecto">
          <el-switch
            v-model="form.porDefecto"
            active-text="Si"
            inactive-text="No"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
        <el-form-item label="Calibres" prop="calibres">
          <maca-select-box
            icon="el-icon-s-operation"
            v-model="calibreSeleccionado"
            :url="urlCalibre"
          ></maca-select-box>
          <div
            style="display: flex; margin-top: 10px"
            v-for="(item, index) in form.calibres"
            :key="index"
          >
            <el-input v-model="item.tipoCalibre" disabled></el-input>
            <el-button
              type="default"
              icon="el-icon-close"
              style="margin-left: 10px"
              @click="(calibresManager.remove(item), form.calibres=calibresManager.getUiList())"
              circle
            ></el-button>
          </div>
        </el-form-item>
        <el-form-item label="Zonas" prop="zonas">
          <maca-select-box icon="el-icon-s-flag" v-model="zonaSeleccionada" :url="urlZona"></maca-select-box>
          <div
            style="display: flex; margin-top: 10px"
            v-for="(item, index) in form.zonas"
            :key="index"
          >
            <el-input v-model="item.zona" disabled></el-input>
            <el-button
              type="default"
              icon="el-icon-close"
              style="margin-left: 10px"
              @click="(zonasManager.remove(item), form.zonas=zonasManager.getUiList())"
              circle
            ></el-button>
          </div>
        </el-form-item>
        <el-form-item>
          <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
        </el-form-item>
      </el-form>
    </maca-modal>
  </div>
</template>

<script>
export default {
  name: "modificar-producto",
  data() {
    return {
      form: {
        tipo: null,
        marca: null,
        descripcion: "",
        porDefecto: 0,
        calibres: [],
        zonas: [],
      },
      impedirClose: false,
      cargando: false,
      id: 0,
      calibreSeleccionado: null,
      calibresManager: new this.$listJsonManager(
        "productoCalibreID",
        "productoID"
      ),
      zonaSeleccionada: null,
      zonasManager: new this.$listJsonManager("productoZonaID"),

      urlTipo: this.$api.URL + "/tipoProducto/obtenerTodosSelect",
      urlMarca: this.$api.URL + "/marca/obtenerTodosSelect",
      urlCalibre: this.$api.URL + "/tipoCalibre/obtenerTodosSelect",
      urlZona: this.$api.URL + "/zona/obtenerTodosSelect",

      formRules: {
        tipo: [
          {
            required: true,
            message: "Por favor introduzca el tipo.",
            trigger: "change",
          },
        ],
        marca: [
          {
            required: true,
            message: "Por favor introduzca la marca.",
            trigger: "change",
          },
        ],
        calibres: [
          {
            required: true,
            message: "Por favor agregue al menos un calibre.",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    abrir(id) {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.calibresManager.clear();
      this.zonasManager.clear();

      this.id = id;
      this.getDatos();
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    async getDatos() {
      this.cargando = true;

      let respuestaApi = await this.$api.get(
        this.$api.URL + "/producto/obtenerDatos?productoID=" + this.id,
        this.$usuarioToken()
      );

      this.cargando = false;

      if (respuestaApi != null) {
        this.form.descripcion = respuestaApi.descripcion;
        this.form.porDefecto = respuestaApi.porDefecto;
        this.form.tipo = {
          id: respuestaApi.tipoProductoID,
          nombre: respuestaApi.tipoProducto,
        };
        this.form.marca = {
          id: respuestaApi.marcaID,
          nombre: respuestaApi.marca,
        };
        this.calibresManager.parse(respuestaApi.detalleCalibre);
        this.form.calibres = this.calibresManager.getUiList();

        this.zonasManager.parse(respuestaApi.detalleZona);
        this.form.zonas = this.zonasManager.getUiList();
      } else {
        this.cerrar();
      }
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      // Hacer Post
      let params = {
        productoID: this.id,
        marcaID: this.form.marca.id,
        tipoProductoID: this.form.tipo.id,
        porDefecto: this.form.porDefecto,
        detalleCalibre: JSON.stringify(this.calibresManager.getApiList()),
        detalleZona: JSON.stringify(this.zonasManager.getApiList()),
      };

      if (this.form.descripcion != "" && this.form.descripcion != null) {
        params.descripcion = this.form.descripcion;
      }

      let respuestaApi = await this.$api.post(
        this.$api.URL + "/producto/actualizar",
        params,
        this.$usuarioToken()
      );
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Producto actualizado con éxito!",
          type: "success",
        });

        return true;
      } else {
        if (respuestaApi.estado == -3) {
          this.$message({
            message: "Ya existe un producto con la misma marca y tipo.",
            type: "error",
            showClose: true,
            duration: 0,
          });
        } else if (respuestaApi.estado === 0) {
          this.$message({
            message: respuestaApi.excepcion,
            type: "error",
            showClose: true,
            duration: 0
          });
        } else {
          this.$message({
            message: respuestaApi.mensaje,
            type: "error",
            showClose: true,
            duration: 0
          });
        }
      }
      //

      return false;
    },
  },
  watch: {
    calibreSeleccionado() {
      if (this.calibreSeleccionado != null) {
        this.calibresManager.add({
          tipoCalibreID: this.calibreSeleccionado.id,
          tipoCalibre: this.calibreSeleccionado.nombre,
        });

        this.form.calibres = this.calibresManager.getUiList();
      }
    },
    zonaSeleccionada() {
      if (this.zonaSeleccionada != null) {
        this.zonasManager.add({
          zonaID: this.zonaSeleccionada.id,
          zona: this.zonaSeleccionada.nombre,
        });

        this.form.zonas = this.zonasManager.getUiList();
      }
    },
  },
};
</script>
