<template>
  <div>
    <div class="contenedor-titulo">
      <div class="titulo">
        <h3>
          <i class="el-icon-s-goods"></i>Productos
        </h3>
      </div>
      <div class="botones">
        <el-button
          type="primary"
          icon="el-icon-plus"
          plain
          @click="$refs.modalNuevo.abrir()"
        >Nuevo Producto</el-button>
      </div>
    </div>
    <el-card :body-style="{ padding: '20px' }">
      <div>
        <maca-datatable
          :url="urlTabla"
          :params="paramsTabla"
          :actualizar.sync="actualizarTabla"
          :bloquear.sync="bloquearTabla"
        >
          <el-table-column label="Por Defecto">
            <template slot-scope="props">
              <el-switch
                v-model="props.row.porDefecto"
                :active-value="1"
                :inactive-value="0"
                active-text="Si"
                inactive-text="No"
                @change="togglePorDefecto(props.row.id, props.row.porDefecto)"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="Tipo">
            <template slot="header">
              <maca-datatable-filtrar-select label="Tipo" v-model="filtroTipoProducto" :urlSelect="urlTipoProducto"></maca-datatable-filtrar-select>
            </template>
            <template slot-scope="props">
              <span v-text="props.row.tipoProducto"></span>
            </template>
          </el-table-column>
          <el-table-column label="Marca">
            <template slot="header">
              <maca-datatable-filtrar-select label="Marca" v-model="filtroMarca" :urlSelect="urlMarca"></maca-datatable-filtrar-select>
            </template>
            <template slot-scope="props">
              <span v-text="props.row.marca"></span>
            </template>
          </el-table-column>
          <el-table-column label="Zonas">
            <template slot="header">
              <maca-datatable-filtrar-select label="Zona" v-model="filtroZona" :urlSelect="urlZona"></maca-datatable-filtrar-select>
            </template>
            <template slot-scope="props">
              <el-tooltip v-if="props.row.detalleZona != null" class="item" effect="dark" placement="bottom-start">
                <div slot="content">
                  <span v-for="(item, index) in props.row.detalleZona" :key="index">
                  <span>{{item.zona}}</span>
                  <span
                      v-if="index != props.row.detalleZona.length - 1"
                      style="margin-right: 7px; margin-left: 7px"
                  >-</span>
                  </span>
                </div>
                <el-tag style="margin-top: 3px; margin-bottom: 3px" type="info">
                  {{props.row.detalleZona.length > 0 ? props.row.detalleZona.length + ' zonas' : '-'}}
                </el-tag>
              </el-tooltip>
              <el-tag v-else style="margin-top: 3px; margin-bottom: 3px" type="info">-</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="Calibres">
            <template slot="header">
              <maca-datatable-filtrar-select label="Calibre" v-model="filtroTipoCalibre" :urlSelect="urlTipoCalibre"></maca-datatable-filtrar-select>
            </template>
            <template slot-scope="props">
              <el-tooltip v-if="props.row.detalleCalibre != null" class="item" effect="dark" placement="bottom-start">
                <div slot="content">
                  <span v-for="(item, index) in props.row.detalleCalibre" :key="index">
                  <span>{{item.tipoCalibre}}</span>
                  <span
                      v-if="index != props.row.detalleCalibre.length - 1"
                      style="margin-right: 7px; margin-left: 7px"
                  >-</span>
                  </span>
                </div>
                <el-tag style="margin-top: 3px; margin-bottom: 3px" type="info">
                  {{props.row.detalleCalibre.length}} calibres
                </el-tag>
              </el-tooltip>
              <el-tag v-else style="margin-top: 3px; margin-bottom: 3px" type="info">-</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="Ver" width="70">
            <template slot-scope="props">
              <el-button
                  type="success"
                  size="small"
                  @click="$refs.modalVer.abrir(props.row.id)"
                  plain
                  round
              >
                <i class="el-icon-view"></i>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="Editar" width="70">
            <template slot-scope="props">
              <el-button
                type="warning"
                size="small"
                @click="$refs.modalModificar.abrir(props.row.id)"
                plain
                round
              >
                <i class="el-icon-edit"></i>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="Borrar" width="70">
            <template slot-scope="props">
              <el-button type="danger" size="small" @click="eliminar(props.row.id)" plain round>
                <i class="el-icon-delete"></i>
              </el-button>
            </template>
          </el-table-column>
        </maca-datatable>
      </div>
    </el-card>
    <modal-ver ref="modalVer" @actualizar-tabla="actualizarTabla = true"></modal-ver>
    <modal-nuevo ref="modalNuevo" @actualizar-tabla="actualizarTabla = true"></modal-nuevo>
    <modal-modificar ref="modalModificar" @actualizar-tabla="actualizarTabla = true"></modal-modificar>
  </div>
</template>

<script>
import ModalNuevo from "./modales/nuevo";
import ModalModificar from "./modales/modificar";
import ModalVer from "./modales/ver";

export default {
  name: "producto",
  components: {
    ModalVer,
    ModalNuevo,
    ModalModificar,
  },
  data() {
    return {
      urlTabla: this.$api.URL + "/producto/obtenerTodos",
      paramsTabla: [
          'incluirCalibres=1'
      ],
      actualizarTabla: true,
      bloquearTabla: true,

      urlTipoCalibre: this.$api.URL + "/tipoCalibre/obtenerTodosSelect",
      urlTipoProducto: this.$api.URL + "/tipoProducto/obtenerTodosSelect",
      urlMarca: this.$api.URL + "/marca/obtenerTodosSelect",
      urlZona: this.$api.URL + "/zona/obtenerTodosSelect",

      filtroNombre: null,
      filtroTipoProducto: null,
      filtroTipoCalibre: null,
      filtroMarca: null,
      filtroZona: null,
    };
  },
  methods: {
    async eliminar(id) {
      let confirmado = await this.$confirm(
        "Confirme que desea eliminar el producto."
      );

      if (!confirmado) {
        return;
      }

      this.bloquearTabla = true;

      // Hacer post
      let params = { productoID: id };

      let respuestaApi = await this.$api.post(
        this.$api.URL + "/producto/eliminar",
        params,
        this.$usuarioToken()
      );
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Producto borrado con éxito!",
          type: "success",
        });
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
    async togglePorDefecto(id, nuevoValor) {
      this.bloquearTabla = true;

      // Hacer post
      let params = { productoID: id, porDefecto: nuevoValor };

      let respuestaApi = await this.$api.post(
        this.$api.URL + "/producto/actualizarPorDefecto",
        params,
        this.$usuarioToken()
      );
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Datos del producto cambiados con éxito!",
          type: "success",
        });
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
  },
  watch: {
    filtroTipoProducto() {
      if (this.filtroTipoProducto != null) {
        this.$common.agregarUrlParam(
          this.paramsTabla,
          "tipoProductoID",
          JSON.stringify(this.filtroTipoProducto.id)
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "tipoProductoID");
      }

      this.actualizarTabla = true;
    },
    filtroTipoCalibre() {
      if (this.filtroTipoCalibre != null) {
        this.$common.agregarUrlParam(
            this.paramsTabla,
            "tipoCalibreID",
            JSON.stringify(this.filtroTipoCalibre.id)
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "tipoCalibreID");
      }

      this.actualizarTabla = true;
    },
    filtroMarca() {
      if (this.filtroMarca != null) {
        this.$common.agregarUrlParam(
            this.paramsTabla,
            "marcaID",
            JSON.stringify(this.filtroMarca.id)
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "marcaID");
      }

      this.actualizarTabla = true;
    },
    filtroZona() {
      if (this.filtroZona != null) {
        this.$common.agregarUrlParam(
            this.paramsTabla,
            "zonaID",
            JSON.stringify(this.filtroZona.id)
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "zonaID");
      }

      this.actualizarTabla = true;
    },
  },
};
</script>

