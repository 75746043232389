var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "maca-modal",
        {
          ref: "modal",
          attrs: {
            titulo: "Modificar Producto",
            impedirClose: _vm.impedirClose
          }
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.cargando,
                  expression: "cargando"
                }
              ],
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.formRules,
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Tipo", prop: "tipo" } },
                [
                  _c("maca-select-box", {
                    attrs: { icon: "el-icon-box", url: _vm.urlTipo },
                    model: {
                      value: _vm.form.tipo,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "tipo", $$v)
                      },
                      expression: "form.tipo"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Marca", prop: "marca" } },
                [
                  _c("maca-select-box", {
                    attrs: {
                      icon: "el-icon-collection-tag",
                      url: _vm.urlMarca
                    },
                    model: {
                      value: _vm.form.marca,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "marca", $$v)
                      },
                      expression: "form.marca"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Descripción", prop: "descripcion" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.descripcion,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "descripcion", $$v)
                      },
                      expression: "form.descripcion"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Por Defecto", prop: "porDefecto" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-text": "Si",
                      "inactive-text": "No",
                      "active-value": 1,
                      "inactive-value": 0
                    },
                    model: {
                      value: _vm.form.porDefecto,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "porDefecto", $$v)
                      },
                      expression: "form.porDefecto"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Calibres", prop: "calibres" } },
                [
                  _c("maca-select-box", {
                    attrs: { icon: "el-icon-s-operation", url: _vm.urlCalibre },
                    model: {
                      value: _vm.calibreSeleccionado,
                      callback: function($$v) {
                        _vm.calibreSeleccionado = $$v
                      },
                      expression: "calibreSeleccionado"
                    }
                  }),
                  _vm._l(_vm.form.calibres, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticStyle: { display: "flex", "margin-top": "10px" }
                      },
                      [
                        _c("el-input", {
                          attrs: { disabled: "" },
                          model: {
                            value: item.tipoCalibre,
                            callback: function($$v) {
                              _vm.$set(item, "tipoCalibre", $$v)
                            },
                            expression: "item.tipoCalibre"
                          }
                        }),
                        _c("el-button", {
                          staticStyle: { "margin-left": "10px" },
                          attrs: {
                            type: "default",
                            icon: "el-icon-close",
                            circle: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.calibresManager.remove(item),
                                (_vm.form.calibres = _vm.calibresManager.getUiList())
                            }
                          }
                        })
                      ],
                      1
                    )
                  })
                ],
                2
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Zonas", prop: "zonas" } },
                [
                  _c("maca-select-box", {
                    attrs: { icon: "el-icon-s-flag", url: _vm.urlZona },
                    model: {
                      value: _vm.zonaSeleccionada,
                      callback: function($$v) {
                        _vm.zonaSeleccionada = $$v
                      },
                      expression: "zonaSeleccionada"
                    }
                  }),
                  _vm._l(_vm.form.zonas, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticStyle: { display: "flex", "margin-top": "10px" }
                      },
                      [
                        _c("el-input", {
                          attrs: { disabled: "" },
                          model: {
                            value: item.zona,
                            callback: function($$v) {
                              _vm.$set(item, "zona", $$v)
                            },
                            expression: "item.zona"
                          }
                        }),
                        _c("el-button", {
                          staticStyle: { "margin-left": "10px" },
                          attrs: {
                            type: "default",
                            icon: "el-icon-close",
                            circle: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.zonasManager.remove(item),
                                (_vm.form.zonas = _vm.zonasManager.getUiList())
                            }
                          }
                        })
                      ],
                      1
                    )
                  })
                ],
                2
              ),
              _c(
                "el-form-item",
                [
                  _c("maca-boton-guardar", {
                    attrs: { onSubmit: _vm.onSubmit }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }