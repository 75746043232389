var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "maca-modal",
        {
          ref: "modal",
          attrs: {
            titulo: "Datos de Producto",
            "solicitar-confirmacion-close": false
          }
        },
        [
          _vm.cargando
            ? _c("span", [_vm._v("Cargando...")])
            : _c("div", [
                _vm.datos
                  ? _c("table", { staticClass: "tabla-info" }, [
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [_vm._v("Tipo")]),
                          _c("td", {
                            domProps: {
                              textContent: _vm._s(_vm.datos.tipoProducto)
                            }
                          })
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Marca")]),
                          _c("td", {
                            domProps: { textContent: _vm._s(_vm.datos.marca) }
                          })
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Descripción")]),
                          _c("td", {
                            domProps: {
                              textContent: _vm._s(_vm.datos.descripcion)
                            }
                          })
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Por Defecto")]),
                          _c("td", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.datos.porDefecto ? "Si" : "No"
                              )
                            }
                          })
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Cant. Calibres")]),
                          _c("td", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.datos.detalleCalibre
                                  ? _vm.datos.detalleCalibre.length
                                  : ""
                              )
                            }
                          })
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Calibres")]),
                          _vm.datos.detalleCalibre != null
                            ? _c("td", [
                                _c(
                                  "table",
                                  { staticClass: "detalleCalibre" },
                                  _vm._l(_vm.datos.detalleCalibre, function(
                                    item,
                                    index
                                  ) {
                                    return _c("tr", { key: index }, [
                                      _c("td", {
                                        domProps: {
                                          textContent: _vm._s(item.tipoCalibre)
                                        }
                                      })
                                    ])
                                  }),
                                  0
                                )
                              ])
                            : _c("td")
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Zonas")]),
                          _vm.datos.detalleZona != null
                            ? _c("td", [
                                _c(
                                  "table",
                                  { staticClass: "detalleZona" },
                                  _vm._l(_vm.datos.detalleZona, function(
                                    item,
                                    index
                                  ) {
                                    return _c("tr", { key: index }, [
                                      _c("td", {
                                        domProps: {
                                          textContent: _vm._s(item.zona)
                                        }
                                      })
                                    ])
                                  }),
                                  0
                                )
                              ])
                            : _c("td")
                        ])
                      ])
                    ])
                  : _vm._e()
              ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }