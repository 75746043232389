var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "contenedor-titulo" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "botones" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-plus", plain: "" },
                on: {
                  click: function($event) {
                    return _vm.$refs.modalNuevo.abrir()
                  }
                }
              },
              [_vm._v("Nuevo Producto")]
            )
          ],
          1
        )
      ]),
      _c("el-card", { attrs: { "body-style": { padding: "20px" } } }, [
        _c(
          "div",
          [
            _c(
              "maca-datatable",
              {
                attrs: {
                  url: _vm.urlTabla,
                  params: _vm.paramsTabla,
                  actualizar: _vm.actualizarTabla,
                  bloquear: _vm.bloquearTabla
                },
                on: {
                  "update:actualizar": function($event) {
                    _vm.actualizarTabla = $event
                  },
                  "update:bloquear": function($event) {
                    _vm.bloquearTabla = $event
                  }
                }
              },
              [
                _c("el-table-column", {
                  attrs: { label: "Por Defecto" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("el-switch", {
                            attrs: {
                              "active-value": 1,
                              "inactive-value": 0,
                              "active-text": "Si",
                              "inactive-text": "No"
                            },
                            on: {
                              change: function($event) {
                                return _vm.togglePorDefecto(
                                  props.row.id,
                                  props.row.porDefecto
                                )
                              }
                            },
                            model: {
                              value: props.row.porDefecto,
                              callback: function($$v) {
                                _vm.$set(props.row, "porDefecto", $$v)
                              },
                              expression: "props.row.porDefecto"
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _c(
                  "el-table-column",
                  {
                    attrs: { label: "Tipo" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(props.row.tipoProducto)
                              }
                            })
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("maca-datatable-filtrar-select", {
                          attrs: {
                            label: "Tipo",
                            urlSelect: _vm.urlTipoProducto
                          },
                          model: {
                            value: _vm.filtroTipoProducto,
                            callback: function($$v) {
                              _vm.filtroTipoProducto = $$v
                            },
                            expression: "filtroTipoProducto"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  2
                ),
                _c(
                  "el-table-column",
                  {
                    attrs: { label: "Marca" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c("span", {
                              domProps: { textContent: _vm._s(props.row.marca) }
                            })
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("maca-datatable-filtrar-select", {
                          attrs: { label: "Marca", urlSelect: _vm.urlMarca },
                          model: {
                            value: _vm.filtroMarca,
                            callback: function($$v) {
                              _vm.filtroMarca = $$v
                            },
                            expression: "filtroMarca"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  2
                ),
                _c(
                  "el-table-column",
                  {
                    attrs: { label: "Zonas" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            props.row.detalleZona != null
                              ? _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      placement: "bottom-start"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content"
                                      },
                                      _vm._l(props.row.detalleZona, function(
                                        item,
                                        index
                                      ) {
                                        return _c("span", { key: index }, [
                                          _c("span", [
                                            _vm._v(_vm._s(item.zona))
                                          ]),
                                          index !=
                                          props.row.detalleZona.length - 1
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-right": "7px",
                                                    "margin-left": "7px"
                                                  }
                                                },
                                                [_vm._v("-")]
                                              )
                                            : _vm._e()
                                        ])
                                      }),
                                      0
                                    ),
                                    _c(
                                      "el-tag",
                                      {
                                        staticStyle: {
                                          "margin-top": "3px",
                                          "margin-bottom": "3px"
                                        },
                                        attrs: { type: "info" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              props.row.detalleZona.length > 0
                                                ? props.row.detalleZona.length +
                                                    " zonas"
                                                : "-"
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  "el-tag",
                                  {
                                    staticStyle: {
                                      "margin-top": "3px",
                                      "margin-bottom": "3px"
                                    },
                                    attrs: { type: "info" }
                                  },
                                  [_vm._v("-")]
                                )
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("maca-datatable-filtrar-select", {
                          attrs: { label: "Zona", urlSelect: _vm.urlZona },
                          model: {
                            value: _vm.filtroZona,
                            callback: function($$v) {
                              _vm.filtroZona = $$v
                            },
                            expression: "filtroZona"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  2
                ),
                _c(
                  "el-table-column",
                  {
                    attrs: { label: "Calibres" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            props.row.detalleCalibre != null
                              ? _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      placement: "bottom-start"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content"
                                      },
                                      _vm._l(props.row.detalleCalibre, function(
                                        item,
                                        index
                                      ) {
                                        return _c("span", { key: index }, [
                                          _c("span", [
                                            _vm._v(_vm._s(item.tipoCalibre))
                                          ]),
                                          index !=
                                          props.row.detalleCalibre.length - 1
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-right": "7px",
                                                    "margin-left": "7px"
                                                  }
                                                },
                                                [_vm._v("-")]
                                              )
                                            : _vm._e()
                                        ])
                                      }),
                                      0
                                    ),
                                    _c(
                                      "el-tag",
                                      {
                                        staticStyle: {
                                          "margin-top": "3px",
                                          "margin-bottom": "3px"
                                        },
                                        attrs: { type: "info" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              props.row.detalleCalibre.length
                                            ) +
                                            " calibres\n              "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  "el-tag",
                                  {
                                    staticStyle: {
                                      "margin-top": "3px",
                                      "margin-bottom": "3px"
                                    },
                                    attrs: { type: "info" }
                                  },
                                  [_vm._v("-")]
                                )
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("maca-datatable-filtrar-select", {
                          attrs: {
                            label: "Calibre",
                            urlSelect: _vm.urlTipoCalibre
                          },
                          model: {
                            value: _vm.filtroTipoCalibre,
                            callback: function($$v) {
                              _vm.filtroTipoCalibre = $$v
                            },
                            expression: "filtroTipoCalibre"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  2
                ),
                _c("el-table-column", {
                  attrs: { label: "Ver", width: "70" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "success",
                                size: "small",
                                plain: "",
                                round: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$refs.modalVer.abrir(props.row.id)
                                }
                              }
                            },
                            [_c("i", { staticClass: "el-icon-view" })]
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "Editar", width: "70" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "warning",
                                size: "small",
                                plain: "",
                                round: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$refs.modalModificar.abrir(
                                    props.row.id
                                  )
                                }
                              }
                            },
                            [_c("i", { staticClass: "el-icon-edit" })]
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "Borrar", width: "70" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "danger",
                                size: "small",
                                plain: "",
                                round: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.eliminar(props.row.id)
                                }
                              }
                            },
                            [_c("i", { staticClass: "el-icon-delete" })]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("modal-ver", {
        ref: "modalVer",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      }),
      _c("modal-nuevo", {
        ref: "modalNuevo",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      }),
      _c("modal-modificar", {
        ref: "modalModificar",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "titulo" }, [
      _c("h3", [
        _c("i", { staticClass: "el-icon-s-goods" }),
        _vm._v("Productos\n      ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }